<template name="mdm-link">
  <component
    :is="component"
    v-bind="link">
    <slot />
  </component>
</template>
<script>
export default {
  name: 'MdmLink',
  props: {
    url: {
      type: String,
      default: '',
    },
    isNuxt: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    link() {
      return {
        href: this.url,
      };
    },
    component() {
      return 'a';
    },
  },
};
</script>
