<template name="icon-text-link">
  <a
    :href="url"
    class="d-flex align-items-center"
    :class="themeClass"
    @click.native="$root.$emit('generic-tracking', $event, tracking)">
    <mdm-icon
      size="24"
      class="mr-2 svg"
      :name="icon" />
    <div class="d-flex flex-column">
      <p
        class="m-0 label-main"
        v-text="label" />
      <p
        v-if="description"
        class="m-0 label-secondary"
        v-text="description" />
    </div>
  </a>
</template>

<script>
import MdmIcon from '~/components/atoms/Global/MdmIcon';

export default {
  name: 'IconTextLink',
  components: {
    MdmIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'light',
      validator(value) {
        return ['dark', 'light'].includes(value);
      },
    },
    tracking: {
      type: Object,
      default() { return {}; },
    },
  },
  computed: {
    themeClass() {
      return `theme-${this.theme}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.outstanding-header-container {

  .label-main {
    font-weight: bold;
  }

  .svg {
    transition: fill .2s ease;
  }

  .theme-dark {
    color: $white;

    .svg {
      fill: $white;
    }
  }

  .theme-light {
    color: $color-gris;

    .svg {
      fill: $color-gris;
    }
  }

  .theme-dark,
  .theme-light {
    &:hover {
      svg {
        fill: $color-kraft;
      }
    }
  }
}

</style>
