<template name="NavigationBar">
  <nav
    id="main-navigation"
    class="navigation d-none d-lg-flex container-fluid align-items-center justify-content-between">
    <ul class="d-flex text-center list-unstyled mb-0">
      <li
        v-for="navItem in navigation"
        :key="navItem.id"
        class="px-3 vertical-line">
        <mdm-link
          class="link-item d-block text-uppercase font-weight-semibold"
          :class="[navItem.type !== 'link' && 'pr-4', { 'active': openedMenu === navItem.label }]"
          :url="navItem.url"
          :data-title="navItem.label"
          :style="{ color: navItem.color }"
          @click.native="handleClick(navItem, $event)">
          {{ navItem.label }}
          <mdm-icon
            v-if="navItem.type !== 'link'"
            width="20"
            height="20"
            class="link-chevron"
            name="chevron-down" />
        </mdm-link>
      </li>
    </ul>
    <ul class="d-flex text-center list-unstyled mb-0">
      <li
        v-for="service in services"
        :key="service.id"
        class="pl-4">
        <mdm-link
          class="nav-right link-item font-weight-semibold"
          :url="service.url"
          :data-title="service.label"
          :style="{ color: service.color }"
          @click.native="$root.$emit('generic-tracking', $event, { data: service.tracking });">
          {{ service.label }}
        </mdm-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import '~/assets/icons/svg/chevron-down.svg';

import MdmLink from '~/components/atoms/MdmLink.vue';
import MdmIcon from '~/components/atoms/Global/MdmIcon';

export default {
  name: 'NavigationBar',
  components: {
    MdmLink,
    MdmIcon,
  },
  props: {
    navigation: {
      type: Array,
      required: true,
    },
    services: {
      type: Array,
      required: true,
    },
    openedMenu: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleClick(item, event) {
      if (item.type === 'link') {
        this.$root.$emit('generic-tracking', event, { data: item.tracking });
      } else {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.$emit('select-item', item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  background-color: $white;
  box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .2);
  display: flex;
  height: $navigation-height-lg;
  position: relative;
  z-index: 1;
}

.vertical-line:not(:first-child) {
  border-left: 1px solid $color-gray-nickel;
}

.nav-right {
  @include fontSize(12px);
}

.link-chevron {
  fill: currentColor;
  position: absolute;
  right: 0;
  top: 2px;
  transition: transform .35s ease;
}

.link-item {
  position: relative;

  &.with-chevron {
    padding-right: 20px;
  }

  &:hover,
  &.active {
    font-weight: $font-weight-bold;
  }

  &.active .link-chevron {
    transform: rotate(180deg);
  }

  &::after {
    content: attr(data-title);
    display: block;
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}
</style>
