<i18n>
{
  "fr": {
    "home": "Retourner à l'accueil de Maisons Du Monde",
    "search": "Rechercher",
    "account": "Mon compte",
    "cart": "Voir mon panier"
  },
  "it": {
    "home": "Torna alla home page Maisons Du Monde",
    "search": "Cerca",
    "account": "Il mio conto",
    "cart": "Visualizza il tuo carrello"
  },
  "es": {
    "home": "Volver a la página de inicio Maisons Du Monde",
    "search": "Buscar",
    "account": "Mi cuenta",
    "cart": "Ver mi cesta"
  },
  "de": {
    "home": "Zurück zur Startseite Maisons Du Monde",
    "search": "Suchen",
    "account": "Mein Konto",
    "cart": "Meinen Warenkorb ansehen"
  },
  "en": {
    "home": "Back to Maisons Du Monde home page",
    "search": "Search",
    "account": "My account",
    "cart": "View my basket"
  },
  "pt": {
    "home": "Back to Maisons Du Monde home page",
    "search": "Search",
    "account": "My account",
    "cart": "View my basket"
  },
  "nl": {
    "home": "Terug naar de home page Maisons Du Monde",
    "search": "Zoeken",
    "account": "Mijn account",
    "cart": "Mijn mandje bekijken"
  }
}
</i18n>

<template name="Banner">
  <div class="position-relative d-lg-none">
    <div class="banner-mobile d-flex container-fluid justify-content-between align-items-center">
      <a
        v-once
        :href="homeUrl"
        class="d-inline-block"
        @click.native="tracking($event, { event_action: 'Logo', event_label: 'HomePage' } )">
        <svg
          class="logo-icon fill-white"
          version="1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 595.3 245.5"
          role="img"
          :aria-label="$t('home')">
          <g>
            <path d="M38.7 143.5h518.5v5.6H38.7zM108.8 43.3h.2l20.6 78-19.2 2.6-6.6-37.1h-.2l-20.2 37.1H83L64.7 87h-.2l-6.6 36.9-19.6-2.6 21-78h.2l24.3 47.1 25-47.1zM174.4 74.4l10.2 19H164l10.4-19zm0-31.1l-41.1 76.8 18.6 4 7.8-16.6h29.1l8 16.6 19.4-4-41.8-76.8zM241.9 43.3v78.2l-19 1.8v-78l19-2zM299.5 65.5c-4.4-3.8-7.8-6-13.8-6-3.4 0-8.4 2-8.4 6 0 12.4 34.3 4.2 34.3 32.5 0 17.4-13.4 25.7-29.3 25.7-9.4 0-19.4-2.8-26.7-9l11.4-14.6c3.6 4.2 8.8 6.2 14.4 6.2 4 0 10-2 10-7 0-12-34.3-4.8-34.3-32.5 0-15.2 14.2-23.7 27.9-23.7 9 0 17.8 3.2 24.5 9l-10 13.4zM363.6 60.7c12.2 0 22 11 22 22.9s-9.8 22.9-22 22.9-22-11-22-22.9c-.2-11.8 9.8-22.9 22-22.9zm0-17.4c-22.4 0-41.1 17.8-41.1 40.3s18.6 40.3 41.1 40.3c22.6 0 41.1-17.8 41.1-40.3-.2-22.3-18.8-40.3-41.1-40.3zM486.9 123.9L436.6 80v41.3l-19 1.8V43.3h.2l50.3 43.1V45.1l19-1.8v80.6h-.2zM544.6 65.5c-4.4-3.8-7.8-6-13.8-6-3.4 0-8.4 2-8.4 6 0 12.4 34.3 4.2 34.3 32.5 0 17.4-13.4 25.7-29.3 25.7-9.4 0-19.4-2.8-26.7-9l11.4-14.6c3.6 4.2 8.8 6.2 14.4 6.2 4 0 10-2 10-7 0-12-34.3-4.8-34.3-32.5 0-15.2 14.2-23.7 27.9-23.7 9 0 17.8 3.2 24.5 9l-10 13.4zM48.1 170.2h3c6.2 0 11.2 5.4 11.2 11.4s-5 11.4-11.2 11.4h-3v-22.8zM38.5 201h13.6c11.2 0 20-8.4 20-19.6 0-11-9-19.6-20-19.6H38.5V201zM141.7 187.6c0 9-7 14.4-17 14.4-9 0-15.4-4.2-15.4-13.8v-26.3l9.6-.8v24.3c0 4.2 1.2 7.6 6 7.6 6.2 0 7-4.4 7-9.8v-21.4l9.6-.8.2 26.6zM261.8 161l10.4 39.5-9.8 1.4-3.4-18.8h-.2L248.4 202h-.2l-9.4-18.8h-.2l-3.4 18.8-9.8-1.4 10.8-39.5h.2l12.4 23.7 13-23.8zM326.5 170c6.2 0 11.2 5.6 11.2 11.6s-5 11.6-11.2 11.6c-6.2 0-11.2-5.6-11.2-11.6s5.2-11.6 11.2-11.6zm0-9c-11.4 0-21 9-21 20.6 0 11.4 9.4 20.6 21 20.6s21-9 21-20.6c-.1-11.6-9.4-20.6-21-20.6zM420.1 202l-25.5-22.4v21l-9.6.8v-40.5h.2l25.5 22v-21l9.6-.8-.2 40.9zM469.2 170.2h3c6.2 0 11.2 5.4 11.2 11.4s-5 11.4-11.2 11.4h-3v-22.8zm-9.6 30.8h13.6c11.2 0 20-8.4 20-19.6 0-11-9-19.6-20-19.6h-13.6V201zM530.8 201v-39h22l1.6 8.2h-14.2v6.8h10v8.2h-10v7.4h15.2l-2.4 8.2-22.2.2z" />
          </g>
        </svg>
      </a>
      <ul class="mobile-nav list-unstyled d-flex justify-content-end align-items-center mb-0">
        <li class="d-sm-none">
          <button
            class="search-button p-2"
            @click="toggleSearch()">
            <svg
              class="icon-item d-block"
              :class="isSearchOpen ? 'fill-kraft' : 'fill-white'"
              height="25"
              width="25"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 77.41 82.37"
              :aria-label="$t('search')">
              <path d="M30.81 10c-11.77-1.27-15 17.63-15 26.18a27.43 27.43 0 0 0 20.11 26.61c25.65 7.35 49.85-22.9 38.69-46.69C68.46 3 50.5-1.76 37.12.57c-3.32.58-3.17 5.88 0 6.56C48.38 9.57 63 7 68.29 20.38c4.44 11.21-1.39 25.06-10.91 31.69-9.27 6.46-23.3 5.72-30.67-3.44-3.84-4.77-5.12-11-5-17s1.48-19.17 9.06-19.87a.9.9 0 0 0 0-1.8z" /><path d="M23.42 53.41C20 57 16.59 60.58 12.92 63.95 9.09 67.47 4.56 70.6 1.11 74.49c-3.17 3.59 1 10.19 5.44 7.06S15.14 74 19.1 70.13c3.69-3.64 7.71-7.66 9.86-12.45 1.53-3.41-2.57-7.4-5.54-4.27z" />
            </svg>
          </button>
        </li>
        <li>
          <a
            class="d-block p-2"
            :href="accountUrl"
            rel="nofollow"
            @click="tracking($event, { event_action: 'Mon compte' } )">
            <svg
              class="icon-item d-block fill-white account p-0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 86.78 110.8"
              :aria-label="$t('account')">
              <path d="M2.6 109.84c2.65-10.84 9.09-21 19.78-25.34 1.94-.79 4-1 6-1.69 5.74-2 2.79-6.86.48-10.51-4.64-7.33-6.92-14.67-3-23a18.81 18.81 0 0 1 17.93-11.08c8.82.28 14.51 8.1 15.79 16.21 1.24 7.83-2.43 15.74-8.69 20.57-2.42 1.87-2.9 6.77 1 7.45 12.4 2.2 22.88 14.34 27.09 25.63 1.75 4.69 9 2.89 7.63-2.1-2.19-7.73-7.76-14.64-13.55-20-5.16-5-11.82-10-19-11.35L55 82c11.43-8.7 15.82-22.92 9.88-36.24s-19.89-18-33-12.52C18.46 38.9 13.18 56.09 18.49 69a27.07 27.07 0 0 0 4.18 7.21c.25.3 2.7 3.16 3.06 3.11s.63-2.35.77-2.26c-.11-.07-.43.23-.56.2-1.61-.4-5.76 2.6-7.09 3.34C8.38 86.88 2.44 97.38 0 109.14c-.32 1.62 2.11 2.33 2.51.69z" />
              <g class="account-active-visual">
                <path d="M28.93 27.43a83 83 0 0 1-10.67-14.07c-1.74-2.73-6.14-.13-4.33 2.53a61 61 0 0 0 12.88 13.67c1.48 1.16 3.51-.71 2.12-2.12zm13.38-7.49a34 34 0 0 1-.54-8.82c.24-2.73 1-5.29 1.2-8 .23-4-5.15-4-6.45-.87-2.46 5.87-.32 13.6 2.23 19.17 1 2.14 3.91.51 3.56-1.5zM53 27.08c.53-.3 1.2-.37 1.73-.67A16.86 16.86 0 0 0 56.55 25l3.3-2.53A47.39 47.39 0 0 0 66 17c2.21-2.4-.82-7-3.69-4.79a49.6 49.6 0 0 0-6.38 6l-2.85 3.11a16.8 16.8 0 0 0-1.27 1.38c-.48.67-.67 1.41-1.15 2.09-1 1.41.91 3.06 2.28 2.28z" />
              </g>
              <path
                v-if="connected"
                d="M44.9 94.2c4.3 5.8 8.7 11.7 14.5 16 1.8 1 4 .7 5.5-.7C72.5 101.5 79.6 93 86 84c2.8-4-3.6-9-6.8-5.3-7 8-13.6 16.5-20.7 24.4l5.4-.7c-5.3-3.7-10.1-8.2-15.5-11.8-2-1.3-5.1 1.5-3.5 3.6z"
                fill="green" />
            </svg>
          </a>
        </li>
        <li class="basket-item mr-1">
          <a
            class="basket-btn d-block p-2"
            :href="cartUrl"
            rel="nofollow"
            @click="tracking($event, { event_action: 'Panier' } )">
            <i class="basket icon-item position-relative d-block fill-white">
              <img
                class="basket-icon"
                :src="pictoCart"
                :alt="$t('cart')">
              <span
                v-if="cart"
                class="basket-counter d-inline-block position-absolute bg-white text-center font-weight-bold"
                v-text="cart" />
            </i>
          </a>
        </li>
        <li class="burger-item">
          <button
            class="burger-btn border-0 bg-transparent"
            type="button"
            @click="$emit('open-layer')">
            <svg
              class="burger-icon d-block fill-white m-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 21.09 17.49">
              <path d="M1.38 3.62c5.87.28 11.87.78 17.74.76 2.69 0 2.55-3.8 0-4.09A74.48 74.48 0 0 0 1.38.83a1.4 1.4 0 0 0 0 2.79zm0 6.88c5.63.18 12.34 1.11 17.83-.3 1.39-.36 1.58-2.79 0-3-5.69-.77-12.09.53-17.83.89a1.21 1.21 0 0 0 0 2.42zm-.15 6.95c5.9-.74 11.6.22 17.46 0 1.88-.07 2.45-3 .46-3.41C13.48 12.77 6.55 14 .89 15c-1.38.25-1.08 2.65.33 2.47z" />
            </svg>
            <span
              class="burger-label text-uppercase text-white font-weight-bold"
              v-text="'Menu'" />
          </button>
        </li>
      </ul>
    </div>
    <search-item
      ref="search"
      class="block-searchform position-absolute py-3 px-4 h-100 d-flex align-items-center"
      :class="{ 'is-open': isSearchOpen }"
      :home-url="homeUrl"
      @submitCloseMenu="$emit(closeMenu())" />
  </div>
</template>

<script>
import pictoCart from '~/assets/img/header/picto-cart@2x.png';

const SearchItem = () => import(/* webpackChunkName: "search" */'~/components/specific/TheHeader/Menu/SecondaryNav/Items/SearchItem.vue');

export default {
  name: 'BannerMobile',
  components: {
    SearchItem,
  },
  props: {
    cartUrl: {
      type: String,
      default: '',
    },
    homeUrl: {
      type: String,
      default: '',
    },
    accountUrl: {
      type: String,
      default: '',
    },
    cart: {
      type: Number,
      required: true,
    },
    connected: {
      type: Boolean,
      required: true,
    },
  },
  head() {
    return {
      htmlAttrs: {
        class: this.htmlClasses,
      },
    };
  },
  data() {
    return {
      mobile: true,
      pictoCart,
      search: '',
      isOpen: false,
      isSearchOpen: false,
    };
  },
  computed: {
    htmlClasses() {
      const classes = ['global-wrapper'];
      if (this.isOpen) {
        classes.push('active-mobile');
      }

      return classes.join(' ');
    },
  },
  mounted() {
    this.$root.$on('closeMenu', (shouldChange) => {
      if (shouldChange === false) return;
      this.closeMenu();
    });
  },
  methods: {
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
      if (this.isSearchOpen) {
        this.$refs.search.focus();
      } else {
        this.$refs.search.resetForm();
      }
    },
    tracking(evt, data) {
      this.$root.$emit('generic-tracking', evt, {
        data: {
          event_category: 'Header',
          event_action: '',
          event_label: '',
          ...data,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$logo-width: calculateRem(82px);
$burger-icon-width: calculateRem(23px);
$basket-icon-width: calculateRem(21px);
$account-icon-width: calculateRem(31px);

.banner-mobile {
  background-color: $color-gris;
  height: $banner-height;
  padding-left: $grid-outer-gutter-lg;
  padding-right: $grid-outer-gutter-lg;
  position: relative;
  z-index: 2;
}

.search-button {
  background: transparent;
  border-color: transparent;

  &:focus {
    outline: none;
  }

  .icon-item {
    transition: fill .3s;
  }
}

.logo-icon {
  width: $logo-width;
}

.burger-btn {
  cursor: pointer;
  height: calculateRem(50px);
  line-height: 0;
  padding: calculateRem(10px) calculateRem(15px) calculateRem(6px);

  &:focus {
    outline: none;
  }
}

.burger-icon {
  height: calculateRem(20px);
  line-height: calculateRem(20px);
  width: $burger-icon-width;
}

.burger-label {
  height: calculateRem(14px);
  line-height: calculateRem(14px);

  @include fontSize(8px);
}

.basket-icon {
  height: calculateRem(29px);
  width: $basket-icon-width;
}

.basket-counter {
  border-radius: 7px/50%;
  color: $color-gris;
  font-style: normal;
  line-height: calculateRem(16px);
  min-width: calculateRem(16px);
  padding: 0 calculateRem(2px);
  right: calculateRem(-10px);
  top: calculateRem(20px);

  @include fontSize(10px);
}

.account-active-visual {
  display: none;
}

.account {
  height: calculateRem(31px);
  margin-top: calculateRem(-6px);
  width: $account-icon-width;

  &:active,
  &:focus {
    .account-active-visual {
      display: block;
    }
  }
}

.block-searchform {
  background-image: url('https://www.maisonsdumonde.com/medias/global/pattern-nav-background.png');
  background-repeat: repeat;
  left: 0;
  right: 0;
  top: $banner-height - $layer-mobile-search-height;
  transition: top .3s;
  z-index: 1;

  &.is-open {
    top: 100%;
  }

  /deep/ .search-results {
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    position: fixed;
    top: $banner-height + $layer-mobile-search-height;
  }

  @include media-breakpoint-up(sm) {
    $btns-width: $burger-icon-width + $basket-icon-width + $account-icon-width + 2.5rem;

    background: none;
    margin-left: calc(#{$grid-outer-gutter-lg} + #{$logo-width});
    margin-right: calc(#{$grid-outer-gutter-lg} + #{$btns-width});
    top: ($banner-height - $layer-mobile-search-height) / 2;
    z-index: 3;

    /deep/ .search-results {
      top: $banner-height;
    }
  }
}
</style>
