<i18n>
{
  "fr": {
    "help": "Aide et Contact",
    "store-description": "Trouver un magasin",
    "store": "Magasins"
  },
  "it": {
    "help": "Aiuto e contatti",
    "store-description": "Trova un negozio",
    "store": "Negozi"
  },
  "es": {
    "help": "Ayuda y contacto",
    "store-description": "Encontrar una tienda",
    "store": "Tiendas"
  },
  "de": {
    "help": "Hilfe und Kontakt",
    "store-description": "Ein Geschäft finden",
    "store": "Filialen"
  },
  "en": {
    "help": "Help and contact us",
    "store-description": "Find a store",
    "store": "Stores"
  },
  "pt": {
    "help": "Help and contact us",
    "store-description": "Find a store",
    "store": "Stores"
  },
  "nl": {
    "help": "Hulp en contact",
    "store-description": "Een winkel vinden",
    "store": "Winkels"
  }
}
</i18n>

<template name="MainHeader">
  <header
    class="outstanding-header"
    role="banner">
    <banner
      :cart="cart"
      :user="user"
      :connected="connected"
      :home-url="homeUrl"
      v-bind="urls" />
    <banner-mobile
      :cart="cart"
      :connected="connected"
      v-bind="urls"
      :home-url="homeUrl"
      @open-layer="openMenu('mobile')" />
    <transition
      v-if="navigation"
      name="slide-fade"
      :css="enableNavTransition">
      <navigation-bar
        v-if="displayNavBar"
        class="nav-bar"
        :navigation="navigation"
        :services="navigationServices"
        :opened-menu="isMenuOpened"
        @select-item="openMenu" />
    </transition>
    <transition
      v-if="navigation"
      name="slide">
      <layer-fullscreen
        v-show="isMenuOpened"
        class="layer"
        @close-menu="closeMenu()">
        <layer-mobile
          ref="layerMobile"
          class="d-lg-none"
          :navigation="navigation"
          :services="navigationServices"
          :home-url="homeUrl"
          v-bind="urls"
          @close-menu="closeMenu()" />
        <layer-desktop
          v-if="isMenuOpened && isDesktop() && selectedItem"
          v-bind="selectedItem" />
      </layer-fullscreen>
    </transition>
  </header>
</template>

<script>
import '~/assets/icons/svg/infobulle.svg';
import '~/assets/icons/svg/geoloc.svg';

import Banner from '~/components/specific/MainHeader/Banner.vue';
import BannerMobile from '~/components/specific/MainHeader/BannerMobile.vue';
import NavigationBar from '~/components/specific/MainHeader/NavigationBar.vue';
import LayerMobile from '~/components/specific/MainHeader/LayerMobile.vue';

import GetMediaQuery from '~/mixins/GetMediaQuery';
import USE_MERCURE from '~/const/mercure';

const LayerFullscreen = () => import(/* webpackChunkName: "layer" */'~/components/specific/MainHeader/LayerFullscreen.vue');
const LayerDesktop = () => import(/* webpackChunkName: "layer-desktop" */'./LayerDesktop.vue');

export default {
  mixins: [GetMediaQuery],
  name: 'MainHeader',
  components: {
    Banner,
    BannerMobile,
    NavigationBar,
    LayerFullscreen,
    LayerMobile,
    LayerDesktop,
  },
  props: {
    navigation: {
      type: Array,
      required: true,
    },
    navigationServices: {
      type: Array,
      required: true,
    },
    homeUrl: {
      type: String,
      required: true,
    },
    cart: {
      type: Number,
      required: true,
    },
    user: {
      type: Boolean,
      required: true,
    },
    connected: {
      type: Boolean,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMenuOpened: '',
      selectedItem: null,
      scrollPosition: 0,
      displayNavBar: false,
      enableNavTransition: false,
    };
  },
  head() {
    return {
      bodyAttrs: {
        class: this.bodyClasses,
      },
    };
  },
  computed: {
    bodyClasses() {
      return this.isMenuOpened ? 'no-scroll w-100' : '';
    },
    urls() {
      return {
        cartUrl: this.buildCartUrl,
        homeUrl: this.homeUrl,
        storeLocUrl: `${this.homeUrl}/store-locator`,
        helpUrl: `${this.homeUrl}/compte2/aide_accueil.htm`,
        accountUrl: `${this.homeUrl}/account/dashboard`,
      };
    },
    buildCartUrl() {
      return USE_MERCURE(this.locale) ? `${this.homeUrl}/cart` : `${this.homeUrl}/panier.html`;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    this.scrollPosition = window.scrollY;
    this.displayNavBar = !this.isMobile();

    this.$nextTick(() => {
      this.enableNavTransition = true;
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    openMenu(items) {
      this.selectedItem = items;
      if (items === 'mobile') {
        this.isMenuOpened = 'mobile';
      } else {
        if (items && this.isMenuOpened !== items.label) {
          document.body.classList.add('no-scroll');
          this.isMenuOpened = items.label;
        } else {
          this.isMenuOpened = ''; 
          document.body.classList.remove('no-scroll');
        }
        this.lastActiveElement = document.activeElement;
      }
    },
    closeMenu() {
      this.isMenuOpened = '';
      this.selectedItem = null;
      this.$refs.layerMobile.close();
    },
    handleScroll() {
      if (this.isMobile()) {
        this.displayNavBar = false;

        return;
      }

      const scroll = window.scrollY;
      this.displayNavBar = this.scrollPosition > scroll;
      this.scrollPosition = scroll;
    },
  },
};
</script>

<style lang="scss">
.no-scroll, .no-scroll body {
  width: 100%;
  overflow: hidden;
  position: fixed;
}
</style>

<style lang="scss" scoped>
.outstanding-header {
  position: sticky;
  top: 0;
  z-index: $zindex-fixed;

  @include media-breakpoint-up(lg) {
    height: ($banner-height-lg + $navigation-height-lg);
  }

  ul {
    margin-left: 0;
  }

  .row:before {
    display: none;
  }

  /deep/ {
    .search-input {
      background-color: $white;
      border-radius: 50px;
      line-height: normal;
      padding: calculateRem(8px) calculateRem(16px);
    }

    .links-list .item {
      @include fontSize(18px);

      color: $color-gris;
      font-weight: normal;
      transition: none;

      @include media-breakpoint-up(md) {
        @include fontSize(12px);
      }
    }

    .links-list-services .item {
      @include fontSize(16px);
    }
  }
}

.layer.slide-enter-active {
  transition: all .3s ease;
}

.layer.slide-leave-active {
  transition: all .2s ease;
}

.layer.slide-enter,
.layer.slide-leave-to {
  left: 100%;

  @include media-breakpoint-up(lg) {
    height: 0;
    left: 0;
    top: ($banner-height-lg + $navigation-height-lg);

    /deep/ .logo,
    /deep/ .close-field,
    /deep/ .layer-content {
      opacity: 0;
    }
  }
}

.layer.slide-leave,
.layer.slide-enter-to {
  left: 0;

  @include media-breakpoint-up(lg) {
    height: 100%;
    top: ($banner-height-lg + $navigation-height-lg);

    /deep/ .logo,
    /deep/ .close-field,
    /deep/ .layer-content {
      opacity: 1;
    }
  }
}

.layer.slide-leave,
.layer.slide-leave-active {
  @include media-breakpoint-up(lg) {
    transition-delay: 180ms;

    /deep/ .logo,
    /deep/ .close-field,
    /deep/ .layer-content {
      transition: opacity .2s ease;
      transition-delay: 0;
    }
  }
}

.nav-bar.slide-fade-enter,
.nav-bar.slide-fade-leave-to {
  transform: translateY(-$navigation-height-lg);
}

.nav-bar.slide-fade-enter-active,
.nav-bar.slide-fade-leave-active {
  transition: transform .5s;
}
</style>

<style lang="scss">
.outstanding-header-container {
  @import '@/assets/scss/app.scss';
}

#category.layout .master .sidebar { // because navigation outil is sticky, the filter from category page need to be lower
  @include media-breakpoint-up(md) {
    top: $banner-height-lg;
  }
}
</style>
