// this must be an object, for test purposes it's a function

const USE_MERCURE = (locale) => {
  let result = {
    'fr-FR': true,
    'nl-BE': false,
    'fr-BE': false,
    'de-DE': false,
    'es-ES': false,
    'it-IT': false,
    'fr-LU': false,
    'nl-NL': false,
    'de-AT': false,
    'pt-PT': false,
    'de-CH': false,
    'it-CH': false,
    'fr-CH': false,
    'en-GB': true,
  };
  if (process.client && localStorage.getItem('TOGGLE_MERCURE_FR')) {
    result['fr-FR'] = false;
  }
  if (process.client && localStorage.getItem('TOGGLE_MERCURE_UK')) {
    result['en-GB'] = false;
  }
  if (process.client && localStorage.getItem('TOGGLE_MERCURE_ES')) {
    result['es-ES'] = true;
  }
  if (process.client && localStorage.getItem('TOGGLE_MERCURE_DE')) {
    result['de-DE'] = true;
  }
  if (process.client && localStorage.getItem('TOGGLE_MERCURE_IT')) {
    result['it-IT'] = true;
  }
  if (process.client && localStorage.getItem('TOGGLE_MERCURE_AT')) {
    result['de-AT'] = true;
  }

  if (process.client && localStorage.getItem('TOGGLE_MERCURE')) {
    result = {
      'fr-FR': true,
      'nl-BE': true,
      'fr-BE': true,
      'de-DE': true,
      'es-ES': true,
      'it-IT': true,
      'fr-LU': true,
      'nl-NL': true,
      'de-AT': true,
      'pt-PT': true,
      'de-CH': true,
      'it-CH': true,
      'fr-CH': true,
      'en-GB': true,
    };
  }
  return result[locale];
};

export default USE_MERCURE;
