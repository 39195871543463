<i18n>
{
  "fr": {
    "help": "Aide et Contact",
    "store-description": "Trouver un magasin",
    "store": "Magasins"
  },
  "it": {
    "help": "Aiuto e contatti",
    "store-description": "Trova un negozio",
    "store": "Negozi"
  },
  "es": {
    "help": "Ayuda y contacto",
    "store-description": "Encontrar una tienda",
    "store": "Tiendas"
  },
  "de": {
    "help": "Hilfe und Kontakt",
    "store-description": "Ein Geschäft finden",
    "store": "Filialen"
  },
  "en": {
    "help": "Help and contact us",
    "store-description": "Find a store",
    "store": "Stores"
  },
  "pt": {
    "help": "Help and contact us",
    "store-description": "Find a store",
    "store": "Stores"
  },
  "nl": {
    "help": "Hulp en contact",
    "store-description": "Een winkel vinden",
    "store": "Winkels"
  }
}
</i18n>

<template name="layer-mobile">
  <div class="layer-mobile position-relative h-100 vw-100 d-flex flex-column overflow-hidden">
    <search-item
      ref="search"
      :home-url="homeUrl"
      class="block-searchform flex-shrink-0 py-3 px-4"
      @submitCloseMenu="$emit('close-menu')" />
    <div class="flex-shrink-1 pt-2 overflow-auto pt-2">
      <nav-item-tree
        ref="tree"
        :items="flattened"
        :mea="[]"
        with-color />
      <hr class="separator">
      <ul class="services list-unstyled mt-auto">
        <li
          v-for="service in services"
          :key="service.id"
          class="item">
          <mdm-link
            :url="service.url"
            class="item-link"
            v-text="service.label" />
        </li>
      </ul>
      <ul class="footer p-3 m-0 list-unstyled">
        <li>
          <icon-text-link
            class="icon-text-link p-2"
            :icon="'infobulle'"
            :label="$t('help')"
            :url="helpUrl"
            theme="dark" />
        </li>
        <li>
          <icon-text-link
            class="icon-text-link p-2"
            :icon="'geoloc'"
            :label="$t('store')"
            :description="$t('store-description')"
            :url="storeLocUrl"
            theme="dark" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import IconTextLink from '~/components/molecules/Header/IconTextLink.vue';

const MdmLink = () => import('~/components/atoms/MdmLink');
const NavItemTree = () => import('./NavItemTree.vue');
const SearchItem = () => import(/* webpackChunkName: "search" */'~/components/specific/TheHeader/Menu/SecondaryNav/Items/SearchItem.vue');

export default {
  name: 'LayerMobile',
  components: {
    MdmLink,
    IconTextLink,
    NavItemTree,
    SearchItem,
  },
  props: {
    navigation: {
      type: Array,
      required: true,
    },
    services: {
      type: Array,
      required: true,
    },
    helpUrl: {
      type: String,
      default: '',
    },
    storeLocUrl: {
      type: String,
      default: '',
    },
    homeUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    flattened() {
      return this.navigation
        .reduce((acc, item) => acc.concat(item.flatable ? item.items : item), []);
    },
  },
  methods: {
    close() {
      this.$refs.tree.close();
      this.$refs.search.resetForm();
    },
  },
};
</script>
<style lang="scss" scoped>
.layer-mobile {
  left: 0;
  top: 0;

  /deep/ .items-layer.is-open {
    left: 0;
  }

  /deep/ .item {
    padding-left: $layer-mobile-gutter;
  }

  /deep/ .item-link {
    display: block;
    font-size: calculateRem(16px);
    line-height: 1.375;
    padding-bottom: $layer-mobile-vertical;
    padding-top: $layer-mobile-vertical;

    &.level-1 {
      font-size: calculateRem(18px);
      font-weight: 600;
      line-height: 1.333;
    }
  }
}

.block-searchform {
  background: $color-gris;

  /deep/ .search-results {
    bottom: 0;
    overflow-y: scroll;
    top: $layer-mobile-search-height;
  }
}

.overflow-momentum {
  -webkit-overflow-scrolling: touch;
}


.separator {
  margin-left: calculateRem(44px);
  margin-right: calculateRem(44px);
}

.services {
  padding: 0 $layer-mobile-gutter;
}

.footer {
  background-color: #3c3c3b;
}
</style>
