export const DEVICE_MOBILE = 'MOBILE';
export const DEVICE_TABLET = 'TABLET';
export const DEVICE_DESKTOP = 'DESKTOP';

const MOBILE_MQ = {
  BREAKPOINT: '(min-width: 0)',
  DEVICE: DEVICE_MOBILE,
};
const TABLET_MQ = {
  BREAKPOINT: '(min-width: 768px)',
  DEVICE: DEVICE_TABLET,
};
const DESKTOP_MQ = {
  BREAKPOINT: '(min-width: 992px)',
  DEVICE: DEVICE_DESKTOP,
};


const BOOTSTRAP_MQ = [
  DESKTOP_MQ,
  TABLET_MQ,
  MOBILE_MQ,
];

const MEDIA_QUERIES = {
  [DEVICE_DESKTOP]: '(min-width: 992px)',
  [DEVICE_TABLET]: '(min-width: 768px) and (max-width: 991px)',
  [DEVICE_MOBILE]: '(max-width: 767px)',
};

let memoizedMediaQuery = null;

const matchMediaQuery = () => {
  if (process.server) {
    return MOBILE_MQ;
  }

  return BOOTSTRAP_MQ.find(({ BREAKPOINT }) => window.matchMedia(BREAKPOINT).matches) || MOBILE_MQ;
};

const getMediaQuery = () => {
  if (!memoizedMediaQuery) {
    memoizedMediaQuery = matchMediaQuery();
  }

  return memoizedMediaQuery;
};

if (process.client) {
  BOOTSTRAP_MQ.forEach(({ BREAKPOINT }) => {
    const mql = window.matchMedia(BREAKPOINT);
    mql.onchange = () => {
      memoizedMediaQuery = matchMediaQuery();
    };
  });
}

export default {
  methods: {
    isMobile() {
      return getMediaQuery().DEVICE === DEVICE_MOBILE;
    },
    isTablet() {
      return getMediaQuery().DEVICE === DEVICE_TABLET;
    },
    isDesktop() {
      return getMediaQuery().DEVICE === DEVICE_DESKTOP;
    },
    /**
    * Listen when user enters or exits the specified
    * display and execute the callback function
    *
    * @method listenMatchMedia
    * @param {String} device device to listen to (DESKTOP, TABLET or MOBILE)
    * @param {Function} callback callback function to call when matchMedia changes
    */
    listenMatchMedia(device, callback) {
      if (process.client) {
        if (!MEDIA_QUERIES[device]) {
          console.warn(`"${device}" is not a valid device, use one of [${Object.keys(MEDIA_QUERIES)}]`);
          return;
        }
        let mql = window.matchMedia(MEDIA_QUERIES[device]);
        mql.onchange = callback;

        this.$once('hook:beforeDestroy', () => {
          mql.onchange = null;
          mql = null;
        });
      }
    },
    /**
    * Check if the current display matches the specified breakpoint (equals and up)
    *
    * @method checkSpecialBreakpointUp
    * @param {String} breakpoint breakpoint to check (in px or rem, ex: '1025px')
    * @return {Boolean} Returns true on success
    */
    checkSpecialBreakpointUp(breakpoint) {
      return window.matchMedia(`(min-width: ${breakpoint})`).matches;
    },
  },
};
