<i18n>
{
  "fr": {
    "home": "Retourner à l'accueil de Maisons Du Monde",
    "search": "Rechercher",
    "account-description": "Se connecter",
    "account-greeting": "Bonjour {name} !",
    "account": "Mon compte",
    "cart": "Voir mon panier",
    "help": "Aide et Contact",
    "store-description": "Trouver un magasin",
    "store": "Magasins"
  },
  "it": {
    "account": "Mon compte",
    "home": "Torna alla home page Maisons Du Monde",
    "search": "Cerca",
    "account-description": "Connessione",
    "account-greeting": "Ciao {name}!",
    "account": "Il mio conto",
    "cart": "Visualizza il tuo carrello",
    "help": "Aiuto e contatti",
    "store-description": "Trova un negozio",
    "store": "Negozi"
  },
  "es": {
    "account": "Mon compte",
    "home": "Volver a la página de inicio Maisons Du Monde",
    "search": "Buscar",
    "account-description": "Conectarse",
    "account-greeting": "¡Hola {name}!",
    "account": "Mi cuenta",
    "cart": "Ver mi cesta",
    "help": "Ayuda y contacto",
    "store-description": "Encontrar una tienda",
    "store": "Tiendas"
  },
  "de": {
    "account": "Mein Konto",
    "home": "Zurück zur Startseite Maisons Du Monde",
    "search": "Suchen",
    "account-description": "Einloggen",
    "account-greeting": "Guten Tag {name}!",
    "account": "Mein Konto",
    "cart": "Meinen Warenkorb ansehen",
    "help": "Hilfe und Kontakt",
    "store-description": "Ein Geschäft finden",
    "store": "Filialen"
  },
  "en": {
    "account": "Mon compte",
    "home": "Back to Maisons Du Monde home page",
    "search": "Search",
    "account-description": "Login",
    "account-greeting": "Hello {name}!",
    "account": "My account",
    "cart": "View my basket",
    "help": "Help and contact us",
    "store-description": "Find a store",
    "store": "Stores"
  },
  "pt": {
    "account": "Mon compte",
    "home": "Back to Maisons Du Monde home page",
    "search": "Search",
    "account-description": "Login",
    "account-greeting": "Hello {name}!",
    "account": "My account",
    "cart": "View my basket",
    "help": "Help and contact us",
    "store-description": "Find a store",
    "store": "Stores"
  },
  "nl": {
    "home": "Terug naar de home page Maisons Du Monde",
    "search": "Zoeken",
    "account-description": "Aanmelden",
    "account-greeting": "Hallo {name}!",
    "account": "Mijn account",
    "cart": "Mijn mandje bekijken",
    "help": "Hulp en contact",
    "store-description": "Een winkel vinden",
    "store": "Winkels"
  }
}
</i18n>

<template name="Banner">
  <div class="banner d-none d-lg-flex container-fluid justify-content-between align-items-center">
    <search-item
      :home-url="homeUrl"
      class="block-searchform col-4 bloc-02 m-0 p-0" />
    <div class="col-2 offset-1">
      <a
        v-once
        class="d-inline-block"
        :href="homeUrl"
        @click.native="tracking($event, { event_action: 'Logo', event_label: 'HomePage' } )">
        <svg
          class="logo-icon fill-white"
          version="1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 595.3 245.5"
          height="50"
          width="116"
          role="img"
          :aria-label="$t('home')">
          <g>
            <path d="M38.7 143.5h518.5v5.6H38.7zM108.8 43.3h.2l20.6 78-19.2 2.6-6.6-37.1h-.2l-20.2 37.1H83L64.7 87h-.2l-6.6 36.9-19.6-2.6 21-78h.2l24.3 47.1 25-47.1zM174.4 74.4l10.2 19H164l10.4-19zm0-31.1l-41.1 76.8 18.6 4 7.8-16.6h29.1l8 16.6 19.4-4-41.8-76.8zM241.9 43.3v78.2l-19 1.8v-78l19-2zM299.5 65.5c-4.4-3.8-7.8-6-13.8-6-3.4 0-8.4 2-8.4 6 0 12.4 34.3 4.2 34.3 32.5 0 17.4-13.4 25.7-29.3 25.7-9.4 0-19.4-2.8-26.7-9l11.4-14.6c3.6 4.2 8.8 6.2 14.4 6.2 4 0 10-2 10-7 0-12-34.3-4.8-34.3-32.5 0-15.2 14.2-23.7 27.9-23.7 9 0 17.8 3.2 24.5 9l-10 13.4zM363.6 60.7c12.2 0 22 11 22 22.9s-9.8 22.9-22 22.9-22-11-22-22.9c-.2-11.8 9.8-22.9 22-22.9zm0-17.4c-22.4 0-41.1 17.8-41.1 40.3s18.6 40.3 41.1 40.3c22.6 0 41.1-17.8 41.1-40.3-.2-22.3-18.8-40.3-41.1-40.3zM486.9 123.9L436.6 80v41.3l-19 1.8V43.3h.2l50.3 43.1V45.1l19-1.8v80.6h-.2zM544.6 65.5c-4.4-3.8-7.8-6-13.8-6-3.4 0-8.4 2-8.4 6 0 12.4 34.3 4.2 34.3 32.5 0 17.4-13.4 25.7-29.3 25.7-9.4 0-19.4-2.8-26.7-9l11.4-14.6c3.6 4.2 8.8 6.2 14.4 6.2 4 0 10-2 10-7 0-12-34.3-4.8-34.3-32.5 0-15.2 14.2-23.7 27.9-23.7 9 0 17.8 3.2 24.5 9l-10 13.4zM48.1 170.2h3c6.2 0 11.2 5.4 11.2 11.4s-5 11.4-11.2 11.4h-3v-22.8zM38.5 201h13.6c11.2 0 20-8.4 20-19.6 0-11-9-19.6-20-19.6H38.5V201zM141.7 187.6c0 9-7 14.4-17 14.4-9 0-15.4-4.2-15.4-13.8v-26.3l9.6-.8v24.3c0 4.2 1.2 7.6 6 7.6 6.2 0 7-4.4 7-9.8v-21.4l9.6-.8.2 26.6zM261.8 161l10.4 39.5-9.8 1.4-3.4-18.8h-.2L248.4 202h-.2l-9.4-18.8h-.2l-3.4 18.8-9.8-1.4 10.8-39.5h.2l12.4 23.7 13-23.8zM326.5 170c6.2 0 11.2 5.6 11.2 11.6s-5 11.6-11.2 11.6c-6.2 0-11.2-5.6-11.2-11.6s5.2-11.6 11.2-11.6zm0-9c-11.4 0-21 9-21 20.6 0 11.4 9.4 20.6 21 20.6s21-9 21-20.6c-.1-11.6-9.4-20.6-21-20.6zM420.1 202l-25.5-22.4v21l-9.6.8v-40.5h.2l25.5 22v-21l9.6-.8-.2 40.9zM469.2 170.2h3c6.2 0 11.2 5.4 11.2 11.4s-5 11.4-11.2 11.4h-3v-22.8zm-9.6 30.8h13.6c11.2 0 20-8.4 20-19.6 0-11-9-19.6-20-19.6h-13.6V201zM530.8 201v-39h22l1.6 8.2h-14.2v6.8h10v8.2h-10v7.4h15.2l-2.4 8.2-22.2.2z" />
          </g>
        </svg>
      </a>
    </div>
    <ul class="col-5 h-100 m-0 p-0 d-flex list-unstyled justify-content-end align-items-center">
      <li class="h-100 px-2">
        <icon-text-link
          class="icon-text-link h-100"
          icon="infobulle"
          :label="$t('help')"
          :url="helpUrl"
          theme="dark"
          @click.native="tracking($event, { event_action: 'Aide et contact' } )" />
      </li>
      <li class="h-100 px-2">
        <icon-text-link
          class="icon-text-link h-100"
          icon="geoloc"
          :label="$t('store')"
          :description="$t('store-description')"
          :url="storeLocUrl"
          theme="dark"
          @click.native="tracking($event, { event_action: 'Magasins' } )" />
      </li>
      <li class="h-100 px-2">
        <icon-text-link
          class="icon-text-link h-100"
          :icon="accountIcon"
          :label="$t('account')"
          :description="accountDescription"
          :url="accountUrl"
          theme="dark"
          @mouseover.native="accountHover=true"
          @mouseleave.native="accountHover=false"
          @click.native="tracking($event, { event_action: 'Mon compte' } )" />
      </li>
      <li class="basket-item h-100 px-2">
        <a
          class="basket-link h-100 px-2 d-flex align-items-center"
          :href="cartUrl"
          rel="nofollow"
          @click="tracking($event, { event_action: 'Panier' } )">
          <i class="basket position-relative d-block">
            <img
              class="basket-icon"
              :src="pictoCart"
              alt="">
            <span
              v-if="cart"
              class="basket-counter d-inline-block position-absolute bg-white text-center font-weight-bold"
              v-text="cart" />
          </i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import pictoCart from '~/assets/img/header/picto-cart@2x.png';
import '~/assets/icons/svg/account.svg';
import '~/assets/icons/svg/account-on.svg';
import '~/assets/icons/svg/account-connected.svg';
import '~/assets/icons/svg/infobulle.svg';
import '~/assets/icons/svg/geoloc.svg';

import IconTextLink from '~/components/molecules/Header/IconTextLink.vue';

const SearchItem = () => import(/* webpackChunkName: "search" */'~/components/specific/TheHeader/Menu/SecondaryNav/Items/SearchItem.vue');

export default {
  name: 'Banner',
  components: {
    SearchItem,
    IconTextLink,
  },
  props: {
    cartUrl: {
      type: String,
      default: '',
    },
    homeUrl: {
      type: String,
      default: '',
    },
    helpUrl: {
      type: String,
      default: '',
    },
    accountUrl: {
      type: String,
      default: '',
    },
    storeLocUrl: {
      type: String,
      default: '',
    },
    cart: {
      type: Number,
      required: true,
    },
    user: {
      type: Boolean,
      required: true,
    },
    connected: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      pictoCart,
      accountHover: false,
    };
  },
  computed: {
    accountDescription() {
      return this.connected ? this.$t('account-greeting', { name: this.user.firstname }) : this.$t('account-description');
    },
    accountIcon() {
      if (this.accountHover) return 'account-on';
      return this.connected ? 'account-connected' : 'account';
    },
  },
  methods: {
    tracking(evt, data) {
      this.$root.$emit('generic-tracking', evt, {
        data: {
          event_category: 'Header',
          event_action: '',
          event_label: '',
          ...data,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-color: $color-gris;
  height: $banner-height;
  position: inherit;
  z-index: 2;

  @include media-breakpoint-up(md) {
    height: $banner-height-lg;
    width: 100%;
  }
}

.logo-icon {
  height: calculateRem(50px);
  width: calculateRem(116px);
}

.basket-icon {
  height: calculateRem(29px);
  width: calculateRem(21px);
}

.basket-counter {
  border-radius: 7px/50%;
  color: $color-gris;
  font-style: normal;
  line-height: calculateRem(16px);
  min-width: calculateRem(16px);
  padding: 0 calculateRem(2px);
  right: calculateRem(-10px);
  top: calculateRem(20px);

  @include fontSize(10px);
}

.icon-text-link {
  @include fontSize(10px);
}

.basket-link,
.icon-text-link {
  padding-bottom: calculateRem(8px);

  &:hover,
  &.is-active {
    border-bottom: calculateRem(4px) solid $color-indian-khaki;
    padding-bottom: calculateRem(4px) !important;
    transition: all 200ms ease;
  }
}
</style>
